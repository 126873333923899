import { BaseSubPage } from "../components/BaseSubPage"

export const Midwife = () => { 

    return(

            <BaseSubPage title="Jordemoder"> 
                <div className="gap-2 flex flex-col">
                    <h2 className='pt-8 merriweather-bold text-xl mb-2'>Information om jordemoder</h2>
                    <div className="text-left"><p>Telefon: 89045234</p> <p>Adresse: Vestgade 18</p><p>Træffetider: 8-16 man-ons</p></div>
                    <div className="text-left"><p>Ønsker forberedende undervisning</p> <p>Indivuduel konsultation</p></div>
                </div>
            </BaseSubPage>
    )

}