import { create } from 'zustand'

export const userStore = create((set) => ({
  showBirths: false,
  showHistory: false,
  setShowBirths: (show) => set({ showBirths: show }),
  setShowHistory: (show) => set({ showHistory: show }),
  pinnedTiles: [],
  setPinnedTiles: (tiles) => set({ pinnedTiles: tiles }),
}))
