import { Folder } from "@phosphor-icons/react";
import { DataTile } from "../components/DataTile";
import { userStore } from "../stores/userStore";


const dataTiles = [
    // {
    //     title: 'Profil',
    //     description: 'Lav dine personlige indstillinger',
    //     path: '/',
    //     id: 1
    // },
    {
        title: 'Løbende data',
        description: 'Her kan du se data fra denne graviditet',
        path: '/data/currentData',
        id: 2
    },
    {
        title: 'Sygehistorie',
        description: 'Se din sygehistorik',
        path: '/data/history',
        id: 3
    },
    {
        title: 'Moderkagens placering',
        description: 'Her kan du se information din moderkage',
        path: '/data/placenta',
        id: 4
    },
    {
        title: 'Tidligere fødsler',
        description: 'Her kan du se dine tidligere fødsler',
        path: '/data/births',
        id: 5
    },
    {
        title: 'Fødested',
        description: 'Horsens sygehus',
        id: 6
    },
    {
        title: 'Termin',
        description: '26/11/2024',
        id: 7
    },
    {
        title: 'Sundhedsplejerske',
        description: 'Britta Hansen',
        details: <div className="text-left"><p>Telefon: 89045231</p> <p>Adresse: Nøregade 33</p><p>Træffetider: 8-16 man-ons</p></div>,
        id: 8
    },
    {
        title: 'Jordemoder',
        description: 'Hanne Bertelsen',
        path:'/data/midwife',
        details: <div className="text-left"><p>Telefon: 89045234</p> <p>Adresse: Vestgade 18</p><p>Træffetider: 8-16 man-ons</p></div>, 
        id: 9
    },
    {
        title: 'Mit graviditetsminde',
        description: 'Se og udskriv dit barselsminde',
        path:'/data/memory',
        id: 10        
    }
];

export const Data = () => { 

    const { showHistory, showBirths, pinnedTiles } = userStore();

    const tilesWithPinnedProp = dataTiles.map(tile => {
        
        tile.isPinned = pinnedTiles.includes(tile.id);

        return tile;

     }); 

    const sortedDataTiles = tilesWithPinnedProp.sort((a, b) => { 
        if(a.isPinned) return -1;
        if(b.isPinned) return 1;
        return 0;
    });

    return (
        <div>
              <div className='flex flex-row items-center align-center gap-3 bg-orange-50 p-6'>
                <Folder size={30} weight='regular'/> 
                <h1 className="merriweather-bold text-3xl">Gravidmappen </h1>
            </div>
            <div className=' flex flex-1 flex-col items-start justify-start gap-3 p-6'>
                <h2 className='pt-4 merriweather-bold text-xl'>Min graviditet</h2>
                <p className='pb-4'>Her kan du se et overblik over dine data samt kontaktoplyser i graviditeten. 
                </p>
            <div>
                <div className="flex flex-wrap gap-3">
                    <DataTile key={"profile"} title={"Profile"} description={"Lav dine personlige indstillinger"} path={"/"}  enablePinning={false} inverted />
                    {sortedDataTiles.map((tile, index) => { 
                        if(tile.id === "births" && !showBirths) return null
                        if(tile.id === "history" && !showHistory) return null
                        return <DataTile key={index} title={tile.title} description={tile.description} path={tile.path} icon={tile.icon} details={tile.details} isPinned={tile.isPinned} id={tile.id} enablePinning />
                    })}
                </div>
            </div>
        </div>
      </div>
        
    )
}