import { CaretDown, CaretRight, PushPin } from "@phosphor-icons/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../stores/userStore";

export const DataTile = ({title, description, icon, path, details, isPinned, id, enablePinning, inverted}) => {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate ();

    const {pinnedTiles, setPinnedTiles} = userStore();

    const toggleOpen = () => { 
        if(!details && !path) return;
        if(details) {
            setOpen(!open);
        } else if (path) {
            navigate(path);
        }    
    }

    const togglePinned = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        const isPinned = pinnedTiles.includes(id);
        if(isPinned) {
            setPinnedTiles(pinnedTiles.filter(tile => tile !== id));
        } else {
            setPinnedTiles([...pinnedTiles, id]);
        }
    }


    /* ${inverted ? "text-orange-50 bg-[#740037]" : "bg-orange-50"}  */

    return (
        <button onClick={toggleOpen} style={{ height: !open ? "120px" : "200px"}}  className={`${inverted ? "text-orange-50 bg-[#740037]" : "bg-orange-50"} flex-col flex relative justify-between item p-4 rounded-md shadow-sm w-full sm:w-[49%] lg:w-[32%] transition-height ease-in-out duration-200`} >
            {open}
            <div className="w-full">
                <div className="flex flex-row items-center justify-between flex-1">
                    {icon}
                    <h3 className='merriweather-bold text-lg'>{title}</h3>
                    {
                        enablePinning && (
                            <button onClick={(e)=>togglePinned(e, id)}>
                                <PushPin weight={isPinned ? "fill" : "regular"}/>
                            </button>
                        )
                    }
                   
                </div>
                <p className="text-left">{description}</p>
            </div>
            {
                open && (
                <div className="flex flex-1 flex-row justify-between">
                    {details} 
                    {
                         path && (
                            <Link to={path} className="flex flex-row items-center absolute right-5 bottom-5"> <span>Se mere</span> <CaretRight/> </Link>        
                        )
                    }
                    
                </div>)
            }
            {
                !details && path && (
                    <Link to={path} className="flex flex-row items-center absolute right-5 bottom-5"> <span>Se mere</span> <CaretRight/> </Link>        
                )
            }
            {
                details && !open && (
                    <div className="flex flex-row items-center absolute right-5 bottom-5">  <CaretDown/> </div>        
                )
            }
            
        </button>
    )
}