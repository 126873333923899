import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Data } from './views/Data';
import { Profile } from './views/Profile';
import { CurrentData } from './subView/CurrentData';
import { Placenta } from './subView/Placenta';
import { Births } from './subView/Births';
import { Midwife } from './subView/Midwife';
import { History } from './subView/History';
import { Memory } from './subView/Memory';
import { Folder } from '@phosphor-icons/react';


function App() {
  return (
      <div className="bg-[#fad8ca] flex flex-col flex-1">
       
        
          <Routes>
            <Route path="/" element={<Profile />} /> 
            <Route path="/data" element={<Data/>} /> 
            <Route path="/data/currentData" element={<CurrentData/>} /> 
            <Route path="/data/placenta" element={<Placenta/>} /> 
            <Route path="/data/midwife" element={<Midwife/>} /> 
            <Route path="/data/births" element={<Births/>} /> 
            <Route path="/data/history" element={<History />} /> 
            <Route path="/data/memory" element={<Memory />} /> 
        </Routes>
         
      </div>
      
   
  );
}


export default App;
