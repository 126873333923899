import { BaseSubPage } from "../components/BaseSubPage"
import { DataTile } from "../components/DataTile"



export const Births = () => { 

    return(
        <BaseSubPage title={"Tidligere fødsler"}> 
            <p className="mb-4">Her kan du se dine tidligere fødsler. </p>
            <DataTile title={"Dreng 2022"}  description={"Levende født 29 + 4, 3200g, Rask"}/>
        </BaseSubPage>
    )
}