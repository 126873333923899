import { useState } from 'react';
import image from '../assets/mock-weight-graph.png';
import { BaseSubPage } from '../components/BaseSubPage';



export const CurrentData = () => { 

    const [showGraph, setShowGraph] = useState(false);

    return(
        <BaseSubPage title={"Løbende data"}>
            <div className='flex flex-col'>
                <p className="mb-4">Her kan du løbende, igennem din graviditet, følge med i barnets udvikling ligeledes du også kan se dine egne data. 
                </p>
                <h5 className="merriweather-bold">Værdier for dig</h5>
                <p className="mb-4">Blodtryk: 140/90</p>
                <h5 className="merriweather-bold mb-4">Værdier for barnet</h5>

            </div>
           
            <div className='flex flex-col' >
                <div className='h-[338px]'>
             {
                !showGraph && (
                    <div> 
                <table>
                <thead>
                    <tr>
                    <th scope="col">Uge</th>
                    <th scope="col">  

                        <select className='bg-[#740037] max-w-[230px]'>
                            <option>Vægt</option>
                            <option>Vælg en anden værdi, at vise i tabellen</option>
                        </select>                        
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th scope="row">8-10</th>
                    </tr>
                    <tr>
                    <th scope="row">12</th>
                    <td></td>
                    </tr>
                    <tr>
                    <th scope="row">18-20</th>
                    <td></td>
                    </tr>
                    <tr>
                    <th scope="row">25</th>
                    <td>800g</td>
                    </tr>
                    <tr>
                    <th scope="row">28</th>
                    <td>1300g</td>
                    </tr>
                    <tr>
                    <th scope="row">32</th>
                    <td>2700g</td>
                    </tr>
                    <tr>
                    <th scope="row">35</th>
                    <td>3100g</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                    </tr>
                </tfoot>
                </table>

            </div>)
                }
             {
                showGraph && (
                    <img alt="image" src={image}/>
                )
             }
             </div>
               <div className="flex flex-row justify-center">
                    <button onClick={()=>setShowGraph(!showGraph)} className='bg-[#1428F0] p-3 rounded-md text-white shadow-sm w-[92px]'>{showGraph ? "Vis tabel" : "Vis graf"}</button>
                </div>
                </div>
           
        </BaseSubPage>
    )

}