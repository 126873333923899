import { BaseSubPage } from "../components/BaseSubPage"

export const Memory = () => { 

    return(

        <BaseSubPage title={"Mit graviditetsminde"}>
                <p>Det er for mange en helt særlig tid at være gravid. Vi har gjort det nemt for dig at sammensætte dit bedste graviditetsminde, som du kan gemme, printe og altid huske tilbage på. </p>
        </BaseSubPage>
    )
}