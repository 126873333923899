import placenta from '../assets/placenta.png';
import { BaseSubPage } from "../components/BaseSubPage";

export const Placenta = () => { 

    return(
        <BaseSubPage title="Moderkagens placering">
            <div> 
                <p className='mb-10'>
                Her kan du se hvordan moderkagen placerer sig i løbet af graviditeten. For nogen vil den ikke flytte sig, og for andre kan placeringen skifte undervejs. 
                </p>

                <img src={placenta} alt="placeholder"/>
            </div>
        </BaseSubPage>
    )

}