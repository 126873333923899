import { Folder } from "@phosphor-icons/react"
import { Link } from "react-router-dom"
import { userStore } from "../stores/userStore"


export const Profile = () => { 

    const { showBirths, showHistory, setShowBirths, setShowHistory } = userStore();

    return (
        
        <div>  
            <div className='flex flex-row items-center align-center gap-3 bg-orange-50 p-6'>
                <Folder size={30} weight='regular'/> 
                <h1 className="merriweather-bold text-3xl">Gravidmappen </h1>
            </div>
            <div className='flex flex-1 flex-col items-start justify-start gap-2 p-6'>
                <h2 className='pt-4 merriweather-bold text-xl'>Min profil</h2>
                <p className='py-2'>Vælg dine indstillinger for hvad, du ønsker at se i din mappe.</p>
                <div className="flex flex-col">
                    <h3 className='merriweather-bold text-lg pb-2'>Jeg ønsker at se</h3>
                    <div className='flex flex-col gap-2 mb-10'> 
                        <div className='flex flex-row gap-2'>
                            <input onChange={()=>setShowBirths(!showBirths)} type="checkbox" id="births" name="births" checked={showBirths}/>
                            <label for="births"> Tidligere fødsler</label>
                        </div>
                        <div className='flex flex-row flex-1 gap-2'>
                            <input onChange={()=>setShowHistory(!showHistory)} type="checkbox" id="history" name="history" checked={showHistory}/>
                            <label for="history">Sygdomshistorik</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-1 flex-row justify-center">
                <Link to={"/data"} className='bg-[#1428F0] p-3 rounded-md text-white shadow-sm'>Gå til mine data</Link>
            </div>
        </div>
        
    )
}